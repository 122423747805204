































































import Vue from "vue";
import {AccountService} from '@/code/services/user/accountService';
import {EventBus} from '@/code/util/eventBus';
import Swal from 'sweetalert2';
import {Confirm, Loading} from '@/code/util/swalUtil';

export default Vue.extend({

  data() {
    return {
      idAccount: 0,
      configs: "{}",
      labels: "{}",
    }
  },

  mounted() {
    this.loadAccount();
  },

  methods: {
    loadAccount: async function () {
      this.idAccount = parseInt(this.$route.params.id);
      const that = this;

      if (this.idAccount && this.idAccount > 0) {
        const service = new AccountService();
        const selectedAccount = await service.get(this.idAccount, true);
        this.configs = JSON.stringify(selectedAccount.meta_configs || "{}", null, 4);
        this.labels = JSON.stringify(selectedAccount.meta_labels || "{}", null, 4);
      } else {
        Swal.fire({
          title: 'Parâmetro inválido',
          text: 'A loja informada é invalida!',
          icon: 'error'
        }).then(() => that.$router.replace({name: 'assistentAction'}))
      }

    },
    saveAndNextTab: async function () {
      const toSave: any = {
        type: 'account'
      };

      const dirty = await (this.$refs.formLinx as any).flags.dirty;

      if (dirty) {


        try {
          toSave.labels = JSON.parse(this.labels)
        } catch (e) {
          Swal.fire({
            title: 'JSON inválido',
            text: 'Verifique o JSON informado no campo LABELS!',
            icon: 'error'
          })
          return;
        }

        try {
          toSave.configs = JSON.parse(this.configs)
        } catch (e) {
          Swal.fire({
            title: 'JSON inválido',
            text: 'Verifique o JSON informado no campo CONFIGS!',
            icon: 'error'
          })
          return;
        }

        Loading.fire("Salvando os dados!");

        const service = new AccountService();
        const saved = await service.update(this.idAccount, toSave);
        Swal.close();

        if (saved && saved.id > 0) {
          EventBus.$emit('nextAssistentRoute', this.idAccount);
        }

      } else {
        EventBus.$emit('nextAssistentRoute', this.idAccount);
      }
    },
    previousTab: async function () {
      const dirty = await (this.$refs.formLinx as any).flags.dirty;

      if (dirty) {
        Confirm.fire('Os dados informados serão perdidos. Deseja voltar à etapa anterior?')
            .then(result => {
              if (result && result.isConfirmed) {
                EventBus.$emit('previousAssistentRoute');
              }
            });
      } else {
        EventBus.$emit('previousAssistentRoute');
      }
    },
  },

  computed: {
    configString(): String {
      return JSON.stringify(this.configs, null, 2);
    },
  }

});
